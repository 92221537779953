import React from "react";
import { Link } from "gatsby";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaMapMarkedAlt, FaFacebookF, FaPhoneAlt, FaLinkedinIn, FaTwitter, FaInstagram, FaEnvelope  } from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <div className="footer-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7">
              <h3 className="title">Gray Hawk Land Solutions</h3>
              <p className="lead mt-3">
                We offer agile services, better pricing, strategic partnerships,
                and the convenience of artificial intelligence data extraction.
                Our main goal is to provide the best overall service to our
                clients. Let’s discuss how we can best help your company achieve
                its goals.
              </p>
            </div>
            <div className="col-lg-2 quick-links-box">
              <h3 className="title">quick links</h3>
              <div className="quicklinks-content d-flex flex-column mt-3">
                <Link to="/">Home</Link>
                <Link to="/#our-team">Meet the Team</Link>
                <Link to="/#about-section">About</Link>
                <Link to="/#services">Services</Link>
                <Link to="/blog">Blog</Link>
                <Link to="/#contact">Contact</Link>
                <Link to="/join-our-team">Join Our Team</Link>
                <Link
                  to="https://msi.prismhr.com/msi/auth/#/login?lang=en"
                  target="_blank"
                >
                  Employee Login
                </Link>
                <Link to="https://dashboard.grayhawkland.com/" target="_blank">
                  Dashboard
                </Link>
              </div>
            </div>
            <div className="col-lg-3 footer-contact-box">
              <div className="footer-contact">
                <h3 className="title mb-3">contact us</h3>
                <div className="d-flex align-items-center">
                  <FaMapMarkedAlt
                     
                    className="mr-3 text-white"
                  />
                  <p className="text-white mb-0">
                    8547 E. Arapahoe Road, Suite J-529
                    <br />
                    Greenwood Village, CO 80112
                  </p>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <FaEnvelope
                     
                    className="mr-3 text-white"
                  />
                  <a href="mailto:info@grayhawkland.com" className="text-white">
                    info@grayhawkland.com
                  </a>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <FaPhoneAlt
                    
                    className="mr-3 text-white"
                  />
                  <a href="tel:303.223.1665" className="text-white">
                    303.223.1665
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sub-footer-wrapper">
        <div className="container-fluid">
          <div className="sub-footer-box">
            <p className="mb-0">
              Designed and Powered by{" "}
              <a
                href="https://www.webriq.com/"
                target="_blank"
                rel="noreferrer"
              >
                WebriQ
              </a>
            </p>
            <p className="mb-0">
              © {new Date().getFullYear()} GRAY HAWK LAND SOLUTIONS. ALL RIGHTS
              RESERVED.
            </p>
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/gray-hawk-land-solutions/"
              >
                <FaLinkedinIn className="mr-3" />
              </a>
              {/* <Link to="/">
                <FontAwesomeIcon icon={faTwitter} className="mr-3" />
              </Link>
              <Link to="/">
                <FontAwesomeIcon icon={faInstagram} className="mr-3" />
              </Link>
              <Link to="/">
                <FontAwesomeIcon icon={faFacebookF} className="mr-3" />
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
