import React from "react"

import WebriQForm from "@webriq/gatsby-webriq-form"

const ScheduleForm = () => {
  return (
    <>
      <div className="schedule-form-wrapper">
        <WebriQForm
          data-form-id="1f292571-e0df-4f95-96db-ed8d7af5c1c4"
          name="Schedule Form"
          className="schedule-form"
        >
          <div className="form-wrapper">
            <div className="col-12 mb-3">
              <div className="form-group">
                <input
                  className="form-control full-name"
                  type="text"
                  name="Your Name"
                  id="full_name"
                  placeholder="YOUR NAME"
                />
                <input
                  className="form-control email"
                  type="email"
                  name="Email"
                  id="email_add"
                  placeholder="EMAIL"
                  required
                />
                {/* <input
                  className="date-input"
                  type="date"
                  id="birthday"
                  name="birthday"
                /> */}
                <input
                  className="date-input"
                  type="datetime-local"
                  id="meeting-time"
                  name="Meeting Schedule"
                  // value="2018-06-12T19:30"
                  // min="2018-06-07T00:00"
                  // max="2018-06-14T00:00"
                />
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <div className="webriq-recaptcha" />
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <button className="btn hvr-shadow submit-btn" type="submit">
                  SEND
                </button>
              </div>
            </div>
          </div>
        </WebriQForm>
      </div>
    </>
  )
}

export default ScheduleForm
