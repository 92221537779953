import React, { useState } from "react";
import { Link } from "gatsby";
import { UncontrolledCollapse } from "reactstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ScheduleModal from "../modal/schedule";

import { FaPhoneAlt, FaTimes  } from "react-icons/fa";
// import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

// import { faTimes } from "@fortawesome/free-solid-svg-icons";
import logo from "../../images/grayhawk-logo.svg";

const Menu = ({ toggle }) => {
  const menus = [
    {
      title: "home",
      to: "/",
      subMenus: [],
      target: "_self",
    },
    {
      title: "meet the team",
      to: "/#our-team",
      subMenus: [],
      target: "_self",
    },
    {
      title: "about",
      to: "/#about-section",
      subMenus: [],
      target: "_self",
    },
    {
      title: "services",
      to: "/#services",
      subMenus: [],
      target: "_self",
    },
    {
      title: "data migration",
      to:
        "/insiders-guide-to-successful-document-conversion-and-data-migration",
      subMenus: [],
      target: "_self",
    },
    {
      title: "blog",
      to: "/blog",
      subMenus: [],
      target: "_self",
    },
    {
      title: "contact",
      to: "/#contact",
      subMenus: [],
      target: "_self",
    },
    {
      title: "join our team",
      to: "/join-our-team",
      subMenus: [],
      target: "_self",
    },
    {
      title: "Employee Login",
      to: "https://msi.prismhr.com/msi/auth/#/login?lang=en",
      subMenus: [],
      target: "_blank",
    },
    {
      title: "Dashboard",
      to: "https://dashboard.grayhawkland.com/",
      subMenus: [],
      target: "_blank",
    },
  ];

  const SubMenu = ({ title, subMenus }) => {
    return (
      <div>
        <div
          id={title}
          style={{
            padding: "15px 8px 15px 16px",
            borderBottom: "solid 1px #3C3735",
            color: "#818181",
            cursor: "pointer",
          }}
          className="text-uppercase"
        >
          {title}
        </div>
        <UncontrolledCollapse toggler={title}>
          <div className="d-flex flex-column">
            {subMenus.map((item, index) => (
              <MenuLink key={index} to={item.to} target={item.target}>
                {item.title}
              </MenuLink>
            ))}
          </div>
        </UncontrolledCollapse>
      </div>
    );
  };

  const MenuLink = ({ toggle, to, children, target }) => {
    return (
      <Link
        onClick={toggle}
        className="text-uppercase menu-links"
        to={to}
        target={target}
      >
        {children}
      </Link>
    );
  };

  const [scheduleShow, setScheduleShow] = useState(false);

  return (
    <>
      <div className="d-flex flex-column">
        {/* <button onClick={() => toggle()} className="close-btn">
        x
      </button> */}
        <div className="close-box">
          <FaTimes 
            className="close-btn"
            onClick={toggle}
          />
        </div>
        <Link to="/" className="text-logo">
          <img
            // loading="lazy"
            src={logo}
            alt="Grayhawk"
            style={{ paddingBottom: "2rem" }}
          />
        </Link>
        {menus.map((item, index) => {
          if (item.subMenus.length > 0)
            return (
              <SubMenu
                toggle={toggle}
                title={item.title}
                subMenus={item.subMenus}
              />
            );
          return (
            <MenuLink
              toggle={toggle}
              key={index}
              to={item.to}
              target={item.target}
            >
              {item.title}
            </MenuLink>
          );
        })}
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center button-wrapper-mobile">
        <div className="mb-3">
          <button
            onClick={() => setScheduleShow(true)}
            type="button"
            className="request-btn"
          >
            SCHEDULE A MEETING
          </button>
          <ScheduleModal
            show={scheduleShow}
            onHide={() => setScheduleShow(false)}
          />
        </div>
        <div>
          <FaPhoneAlt className="mr-3 text-white" />
          <a href="tel:303.223.1665" className="mr-3 text-white">
            303.223.1665
          </a>
        </div>
      </div>
    </>
  );
};

export default Menu;
