import React from "react"
import SideBar from "react-sidebar"
// import { Motion, spring, presets } from "react-motion"
import Menu from "./menu"

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faBars } from "@fortawesome/free-solid-svg-icons"

// import menuBars from "../../images/menu.svg"

class SideBarMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebarOpen: false,
      sidebarpullRight: true,
      toggle: false,
    }

    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
  }
  onSetSidebarOpen(state) {
    this.setState({ sidebarOpen: state.open, toggle: !this.state.toggle })
  }
  toggleMenu() {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen,
      toggle: !this.state.toggle,
    })
  }

  handleKeyDown = ev => {
    // check keys if you want
    if (ev.keyCode === 13) {
      this.focus()
    }
  }

  focus = () => this.ref.focus

  handleRef = component => {
    this.ref = component
  }

  render() {
    // const style = {
    // 	overflow: "visible",
    // 	cursor: "pointer",
    // 	// disable touch highlighting on devices
    // 	WebkitTapHighlightColor: "rgba(0,0,0,0)",
    // }
    return (
      <SideBar
        sidebar={
          <Menu toggle={this.toggleMenu} /> // Menu list
        }
        open={this.state.sidebarOpen}
        onSetOpen={this.onSetSidebarOpen}
        pullRight={this.state.sidebarpullRight}
        styles={{
          sidebar: {
            background: "#2d2d2d",
            width: "300px",
            position: "fixed",
            zIndex: "3",
          },
          root: { position: "relative", overflow: "visible" },
          overlay: { backgroundColor: "rgba(0, 0, 0, 0.65)", zIndex: 2 },
          content: {
            position: "relative",
            overflow: "visible",
            cursor: "pointer",
          },
        }}
      >
        {/* <div className="text-white">
          <FontAwesomeIcon
            style={{ fontSize: "2rem" }}
            icon={faBars}
            onClick={() => this.toggleMenu()}
            onKeyDown={e => this.handleKeyDown(e)}
          />
        </div> */}
        <button
          className="btn"
          onClick={() => this.toggleMenu()}
          onKeyDown={e => this.handleKeyDown(e)}
        >
          <svg
            style={{ fill: "#ffffff" }}
            xmlns="http://www.w3.org/2000/svg"
            width="27.742"
            height="24.636"
            viewBox="0 0 27.742 24.636"
          >
            <defs></defs>
            <path
              className="a"
              d="M0,24.636v-3.52H17.34v3.52ZM0,14.078v-3.52H27.742v3.52ZM0,3.52V0H27.742V3.52Z"
            />
          </svg>
        </button>
      </SideBar>
    )
  }
}

export default SideBarMenu
