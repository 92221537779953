import React, { useState } from "react"
import { Link } from "gatsby"
import ScheduleModal from "../modal/schedule"
import logo from "../../images/grayhawk-logo.svg"
import { Navbar, Container } from "reactstrap"
import SideBarMenu from "../sidebar/sidebar"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons"
import { FaPhoneAlt } from "react-icons/fa";

const Header = () => {
  const [scheduleShow, setScheduleShow] = useState(false)

  return (
    <>
      <div className="navbar-wrapper">
        <Navbar fixed="top" className="navbar-fixed">
          <Container fluid>
            <div className="mr-auto">
              <Link to="/">
                <img
                  // width={scrollY > 0 ? "100%" : "100%"}
                  id="logo-size"
                  src={logo}
                  alt="Grayhawk"
                  className="logo sepia img-fluid"
                />
              </Link>
            </div>
            <div className="text-right text-lg-left d-block d-lg-flex justify-content-center align-items-center button-wrapper-desktop">
              <div className="order-2 mb-2 mb-lg-0">
                <button
                  onClick={() => setScheduleShow(true)}
                  className="request-btn header-cta mr-3"
                >
                  SCHEDULE A MEETING
                </button>
                <ScheduleModal
                  show={scheduleShow}
                  onHide={() => setScheduleShow(false)}
                />
              </div>
              <div className="order-1">
                <FaPhoneAlt
                   
                  className="mr-3 text-white"
                  style={{ fontSize: "1rem" }}
                />
                <a href="tel:303.223.1665" className="mr-3 text-white">
                  303.223.1665
                </a>
              </div>
            </div>
            <SideBarMenu />
          </Container>
          {/* <Container className="justify-content-end my-3">
            <FontAwesomeIcon icon={faClock} className="mr-3 text-white" />
            <p className="m-0 text-white">mon- sat: 08:00- 18:00</p>
          </Container> */}
        </Navbar>
      </div>
    </>
  )
}

export default Header
